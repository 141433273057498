define("itarp-admin-dashboard/services/opportunity-designer-store", ["exports", "@ember-data/store", "@ember/application"], function (_exports, _store, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ENGINE_NAME = 'opportunity-designer';
  const ADAPTERS = {
    user: 'opportunity-user',
    invitation: 'opportunity-invitation',
    application: 'opportunity-designer'
  };

  class OpportunityDesignerStore extends _store.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "adapter", ENGINE_NAME);
    }

    adapterFor(name) {
      if (name === 'user') return super.adapterFor('opportunity-user');
      if (name === 'invitation') return super.adapterFor('opportunity-invitation');
      const adapter = super.adapterFor(...arguments);
      const customAdapterName = ADAPTERS[name];
      if (customAdapterName) return super.adapterFor(customAdapterName);
      return super.adapterFor(...arguments);
    }

    serializerFor() {
      const serializer = super.serializerFor(...arguments);

      if (serializer.constructor.name === 'ApplicationSerializer') {
        return super.serializerFor(ENGINE_NAME);
      }

      return serializer;
    }

  }

  _exports.default = OpportunityDesignerStore;
});