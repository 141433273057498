define("itarp-admin-dashboard/models/user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)(), _dec7 = (0, _model.attr)(), _dec8 = (0, _model.attr)(), _dec9 = (0, _model.attr)('date'), _dec10 = (0, _model.belongsTo)('profile', {
    async: false
  }), _dec11 = (0, _model.attr)('boolean'), (_class = class UserModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "middleName", _descriptor, this);

      _initializerDefineProperty(this, "firstName", _descriptor2, this);

      _initializerDefineProperty(this, "lastName", _descriptor3, this);

      _initializerDefineProperty(this, "email", _descriptor4, this);

      _initializerDefineProperty(this, "page", _descriptor5, this);

      _initializerDefineProperty(this, "companyId", _descriptor6, this);

      _initializerDefineProperty(this, "jobsCompleted", _descriptor7, this);

      _initializerDefineProperty(this, "isEmailVerified", _descriptor8, this);

      _initializerDefineProperty(this, "createdAt", _descriptor9, this);

      _initializerDefineProperty(this, "profiles", _descriptor10, this);

      _initializerDefineProperty(this, "selectedForBulk", _descriptor11, this);
    }

    get profile() {
      return this.profiles ? this.profiles : {};
    }

    get fullName() {
      const {
        firstName,
        lastName,
        middleName
      } = this;
      return [firstName, middleName, lastName].compact().without('').join(' ');
    }

    get initials() {
      const {
        firstName,
        lastName
      } = this;
      if (!firstName && !lastName) return '';
      return [firstName[0], lastName[0]].compact().join('').toUpperCase();
    }

    get redactedEmail() {
      const res = this.email.split('@');
      return this.redactString(res[0]) + '@' + this.redactString(res[1]);
    }

    redactString(string) {
      return string[0] + '*'.repeat(string.length - 2) + string.slice(-1);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "middleName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "companyId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "jobsCompleted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isEmailVerified", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "profiles", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectedForBulk", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserModel;
});