define("itarp-admin-dashboard/adapters/task-dashboard-invitation", ["exports", "itarp-auth-addon/adapters/ad-token-data"], function (_exports, _adTokenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TaskDashboardInvitationAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "opportunityNamespace", '/opportunity/data_service/opportunities');

      _defineProperty(this, "talentCloudNamespace", '/request-talent_clouds/routing/data_service');
    }

    urlForCreateRecord(modelName, snapshot) {
      const namespace = snapshot.record.attributes.resource_type === 'opportunity' ? this.opportunityNamespace : this.talentCloudNamespace;
      const resourceId = snapshot.record.attributes.resource_id;
      return `${namespace}/${resourceId}/invite_bulk`;
    }

  }

  _exports.default = TaskDashboardInvitationAdapter;
});