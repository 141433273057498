define("itarp-admin-dashboard/models/profile", ["exports", "@ember-data/model", "@ember/template", "itarp-shared-assets-addon/utils/helpers/date-formats", "@ember/utils"], function (_exports, _model, _template, _dateFormats, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _dec54, _dec55, _dec56, _dec57, _dec58, _dec59, _dec60, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53, _descriptor54, _descriptor55, _descriptor56, _descriptor57, _descriptor58, _descriptor59, _descriptor60;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProfileModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('image-url'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('number'), _dec26 = (0, _model.attr)('number'), _dec27 = (0, _model.attr)('boolean'), _dec28 = (0, _model.attr)('boolean'), _dec29 = (0, _model.attr)('boolean'), _dec30 = (0, _model.attr)('boolean'), _dec31 = (0, _model.attr)('boolean'), _dec32 = (0, _model.attr)('boolean'), _dec33 = (0, _model.attr)('boolean'), _dec34 = (0, _model.attr)('boolean'), _dec35 = (0, _model.attr)('boolean'), _dec36 = (0, _model.attr)('boolean'), _dec37 = (0, _model.attr)('boolean'), _dec38 = (0, _model.attr)('boolean'), _dec39 = (0, _model.attr)('boolean'), _dec40 = (0, _model.attr)('boolean'), _dec41 = (0, _model.attr)('boolean'), _dec42 = (0, _model.attr)('boolean'), _dec43 = (0, _model.attr)('boolean'), _dec44 = (0, _model.attr)('boolean'), _dec45 = (0, _model.attr)('boolean'), _dec46 = (0, _model.attr)('boolean'), _dec47 = (0, _model.attr)('boolean', {
    default: false
  }), _dec48 = (0, _model.attr)('boolean'), _dec49 = (0, _model.attr)('date'), _dec50 = (0, _model.attr)('date'), _dec51 = (0, _model.attr)('date'), _dec52 = (0, _model.attr)(), _dec53 = (0, _model.hasMany)('education'), _dec54 = (0, _model.hasMany)('experience'), _dec55 = (0, _model.hasMany)('certification'), _dec56 = (0, _model.hasMany)('taxonomy'), _dec57 = (0, _model.belongsTo)('practice-area'), _dec58 = (0, _model.hasMany)('taxonomy'), _dec59 = (0, _model.hasMany)('practice-area'), _dec60 = (0, _model.hasMany)('taxonomy'), (_class = class ProfileModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "address", _descriptor, this);

      _initializerDefineProperty(this, "travelAvailability", _descriptor2, this);

      _initializerDefineProperty(this, "country", _descriptor3, this);

      _initializerDefineProperty(this, "state", _descriptor4, this);

      _initializerDefineProperty(this, "city", _descriptor5, this);

      _initializerDefineProperty(this, "zipCode", _descriptor6, this);

      _initializerDefineProperty(this, "latitude", _descriptor7, this);

      _initializerDefineProperty(this, "longitude", _descriptor8, this);

      _initializerDefineProperty(this, "phoneNumber", _descriptor9, this);

      _initializerDefineProperty(this, "profilePicture", _descriptor10, this);

      _initializerDefineProperty(this, "professionalRole", _descriptor11, this);

      _initializerDefineProperty(this, "companyName", _descriptor12, this);

      _initializerDefineProperty(this, "linkedinUrl", _descriptor13, this);

      _initializerDefineProperty(this, "resume", _descriptor14, this);

      _initializerDefineProperty(this, "pitch", _descriptor15, this);

      _initializerDefineProperty(this, "pitchVideo", _descriptor16, this);

      _initializerDefineProperty(this, "nationality", _descriptor17, this);

      _initializerDefineProperty(this, "race", _descriptor18, this);

      _initializerDefineProperty(this, "gender", _descriptor19, this);

      _initializerDefineProperty(this, "highlights", _descriptor20, this);

      _initializerDefineProperty(this, "hourlyRate", _descriptor21, this);

      _initializerDefineProperty(this, "status", _descriptor22, this);

      _initializerDefineProperty(this, "salaryExpectation", _descriptor23, this);

      _initializerDefineProperty(this, "hourlyRateCurrency", _descriptor24, this);

      _initializerDefineProperty(this, "yearsOfExperience", _descriptor25, this);

      _initializerDefineProperty(this, "hoursPerWeek", _descriptor26, this);

      _initializerDefineProperty(this, "mentorshipInterest", _descriptor27, this);

      _initializerDefineProperty(this, "disability", _descriptor28, this);

      _initializerDefineProperty(this, "veteran", _descriptor29, this);

      _initializerDefineProperty(this, "hasBusiness", _descriptor30, this);

      _initializerDefineProperty(this, "workAvailable", _descriptor31, this);

      _initializerDefineProperty(this, "usWorkAuthorized", _descriptor32, this);

      _initializerDefineProperty(this, "requiresUsVisaSponsorship", _descriptor33, this);

      _initializerDefineProperty(this, "remote", _descriptor34, this);

      _initializerDefineProperty(this, "localCommuteOnly", _descriptor35, this);

      _initializerDefineProperty(this, "willingToRelocate", _descriptor36, this);

      _initializerDefineProperty(this, "willingToTravel", _descriptor37, this);

      _initializerDefineProperty(this, "contractor", _descriptor38, this);

      _initializerDefineProperty(this, "fullTimeAvailable", _descriptor39, this);

      _initializerDefineProperty(this, "partTimeAvailable", _descriptor40, this);

      _initializerDefineProperty(this, "fullTimePreference", _descriptor41, this);

      _initializerDefineProperty(this, "partTimePreference", _descriptor42, this);

      _initializerDefineProperty(this, "anyPreference", _descriptor43, this);

      _initializerDefineProperty(this, "betweenPartFullTimeAvailable", _descriptor44, this);

      _initializerDefineProperty(this, "office", _descriptor45, this);

      _initializerDefineProperty(this, "outsideOffice", _descriptor46, this);

      _initializerDefineProperty(this, "experfyExpert", _descriptor47, this);

      _initializerDefineProperty(this, "isCloudMember", _descriptor48, this);

      _initializerDefineProperty(this, "dob", _descriptor49, this);

      _initializerDefineProperty(this, "availabiltyDate", _descriptor50, this);

      _initializerDefineProperty(this, "approvedAt", _descriptor51, this);

      _initializerDefineProperty(this, "areasOfExpertise", _descriptor52, this);

      _initializerDefineProperty(this, "educations", _descriptor53, this);

      _initializerDefineProperty(this, "experiences", _descriptor54, this);

      _initializerDefineProperty(this, "certifications", _descriptor55, this);

      _initializerDefineProperty(this, "skills", _descriptor56, this);

      _initializerDefineProperty(this, "practiceArea", _descriptor57, this);

      _initializerDefineProperty(this, "tools", _descriptor58, this);

      _initializerDefineProperty(this, "practiceAreas", _descriptor59, this);

      _initializerDefineProperty(this, "industries", _descriptor60, this);
    }

    get fullAddress() {
      const {
        city,
        state,
        zipCode,
        country
      } = this;
      return [city, state, zipCode, country].compact().without('').join(', ');
    }

    get highlightsSanitized() {
      if (this.highlights) return (0, _template.htmlSafe)(this.highlights);
    }

    get pitchSanitized() {
      if (this.pitch) return (0, _template.htmlSafe)(this.pitch);
    }

    get bornDate() {
      if (this.dob) {
        return moment(this.dob, _dateFormats.FORMATS.LLLL).format(_dateFormats.FORMATS.LLL);
      }
    }

    get workAvailability() {
      if (!this.workAvailable) return '';
      const labels = {
        partTimeAvailable: '0 - 20',
        betweenPartFullTimeAvailable: '20 - 40',
        fullTimeAvailable: '40+'
      };
      const availability = this.getProperties(Object.keys(labels));
      const available = Object.entries(availability).filter(([prop, value]) => !!value).map(([prop]) => labels[prop]).join(' | ');
      return available ? `${available} hrs/wk` : '';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "address", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "travelAvailability", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "city", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "zipCode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "latitude", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "longitude", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "phoneNumber", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "profilePicture", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "professionalRole", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "companyName", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "linkedinUrl", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "resume", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "pitch", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "pitchVideo", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "nationality", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "race", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "gender", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "highlights", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "hourlyRate", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "salaryExpectation", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "hourlyRateCurrency", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "yearsOfExperience", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "hoursPerWeek", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "mentorshipInterest", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "disability", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "veteran", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "hasBusiness", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "workAvailable", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "usWorkAuthorized", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "requiresUsVisaSponsorship", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "remote", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "localCommuteOnly", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "willingToRelocate", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "willingToTravel", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "contractor", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "fullTimeAvailable", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "partTimeAvailable", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "fullTimePreference", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "partTimePreference", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "anyPreference", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "betweenPartFullTimeAvailable", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "office", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "outsideOffice", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "experfyExpert", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "isCloudMember", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "dob", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = _applyDecoratedDescriptor(_class.prototype, "availabiltyDate", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = _applyDecoratedDescriptor(_class.prototype, "approvedAt", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = _applyDecoratedDescriptor(_class.prototype, "areasOfExpertise", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = _applyDecoratedDescriptor(_class.prototype, "educations", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor54 = _applyDecoratedDescriptor(_class.prototype, "experiences", [_dec54], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor55 = _applyDecoratedDescriptor(_class.prototype, "certifications", [_dec55], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor56 = _applyDecoratedDescriptor(_class.prototype, "skills", [_dec56], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor57 = _applyDecoratedDescriptor(_class.prototype, "practiceArea", [_dec57], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor58 = _applyDecoratedDescriptor(_class.prototype, "tools", [_dec58], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor59 = _applyDecoratedDescriptor(_class.prototype, "practiceAreas", [_dec59], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor60 = _applyDecoratedDescriptor(_class.prototype, "industries", [_dec60], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ProfileModel;
});