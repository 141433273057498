define("itarp-admin-dashboard/services/workflow-designer-store", ["exports", "@ember-data/store", "@ember/application"], function (_exports, _store, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ENGINE_NAME = 'workflow-designer';

  class WorkflowDesignerStore extends _store.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "adapter", ENGINE_NAME);
    }

    adapterFor() {
      const adapter = super.adapterFor(...arguments);

      if (adapter.constructor.name === 'ApplicationAdapter') {
        return super.adapterFor(ENGINE_NAME);
      }

      return adapter;
    }

    serializerFor() {
      const serializer = super.serializerFor(...arguments);

      if (serializer.constructor.name === 'ApplicationSerializer') {
        const owner = (0, _application.getOwner)(this);
        return owner.lookup(`serializer:${ENGINE_NAME}`);
      }

      return serializer;
    }

  }

  _exports.default = WorkflowDesignerStore;
});